
html {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
}
* {
	font-family: "Roboto", sans-serif !important;
}

body {
	margin: 0;
	padding: 0;
	height: 100vh;
	max-width: 100vw;
}

#root {
	display: flex;
	flex-direction: column;
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

::-webkit-scrollbar-thumb {
	background: #22aca4;
	border-radius: 7px;
}

.Mui-selected {
	color: #22aca4 !important;
}

.MuiSnackbar-root {
	z-index: 9999 !important;
}