html {
    --primary: #22ACA4;
    --secondary: #F5F5F5;
    --text: #5A5A5A;
    --textSecondary: #A8A8A8;
    --white: #FFFFFF;
    --background: #DEF3F1;
    --red: #FF4B4B;
    --green: #57FF54;
}

.MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15000000596046448px;
    color: var(--text) !important;
}

.MuiDataGrid-cellContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.MuiDataGrid-row:hover {
    color: var(--primary) !important;
    text-decoration: underline;
    cursor: pointer;
}

.MuiDataGrid-columnHeaders {
    background-color: var(--secondary) !important;
}

.leaflet-control-zoom {
    width: 52px !important;
    border: none !important;
}

.leaflet-bar > a:first-child {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.leaflet-bar > a:last-child {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.leaflet-bar > a {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}

.leaflet-bar > a > span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.MuiDataGrid-row:hover {
    background-color: var(--background) !important;
}

.MuiTabs-indicator {
    background-color: var(--primary) !important;
}

.MuiTab-root {
    text-transform: none !important;
}