/* 
  This file contains the CSS for the map component. 
  It is used to style the map and the clusters.
 */

.leaflet-container {
    flex: 1;
    height: 100%;
    margin: auto;
}

.marker-cluster-red {
    background-color: rgba(239, 83, 80, 0.4);
}

.marker-cluster-red div {
    background-color: rgba(239, 83, 80, 0.6);
}

.marker-cluster-blue {
    background-color: rgba(23, 245, 146, 0.4);
}

.marker-cluster-blue div {
    background-color: rgba(23, 245, 146, 0.6);
}

.leaflet-container img.leaflet-tile {
    mix-blend-mode: initial !important;
}

.transparent-no-border {
    background: transparent;
    border: none;
}

.marker-object {
    background-color: rgb(89, 169, 255);
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.custom-selected-marker {
    opacity: 0.9 !important;
}

.leaflet-popup-content {
    min-width: 400px !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 400px) {
    .leaflet-popup-content {
        min-width: 300px !important;
    }
    
}

.leaflet-popup-content-wrapper {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.leaflet-popup-tip {
    opacity: 0;
}

.tooltip-jail {
    font-size: 14px;
    z-index: -100;
    position: relative;
}

.leaflet-tooltip {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.boat-div-icon {
    z-index: 99999 !important;
}